import { ILoginFormValues } from "./interface";
import { Buffer } from 'buffer';

const urlString = window.location.href; // NOTE: Pega o Url inteira, EX: http://localhost:3001/?url=http://localhost:3000/#/dashboard&rules=supply-chain

const paramString = urlString.split("?")[1]; // NOTE: Pega apenas a url Parametro, Ex: url=http://localhost:3000/#/dashboard&rules=supply-chain

const queryString = new URLSearchParams(paramString);

const [initialEmail, initialPassword] = (() => {
  const rawToken = queryString.get("token");
  if (rawToken) {
    return Buffer.from(rawToken, "base64").toString("utf-8").split(' ') as [string, string];
  }

  return ["", ""]
})();

export const initialValuesLoginForm: ILoginFormValues = { 
  email: initialEmail, 
  password: initialPassword, 
  rules: "" 
}