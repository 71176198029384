import { StandardTextFieldProps, TextField, Typography } from "@mui/material";
import { alpha, styled, TypographyProps } from "@mui/system";
import { inputLabelClasses } from "@mui/material/InputLabel";

const enumType = {
  EMAIL: "email",
  PASSWORD: "password",
};
export interface StyledTextFieldProps extends StandardTextFieldProps {
  labelprops?: {
    label: string;
    hasTouched?: boolean;
    hasErros?: string;
  } & TypographyProps;
  variant?: "standard";
}

const StyledTextField = styled((props: StyledTextFieldProps) => (
  <>
    {props.labelprops?.label && props.variant === "standard" && (
      <Typography
        color="titles.main"
        sx={{
          fontSize: { xs: "1.6rem" },
          fontWeight: "bold",
          mb: { xs: 2 },
        }}
        {...props.labelprops}
      >
        {props.labelprops?.label}
      </Typography>
    )}

    <TextField
      id={`outlined-basic-${props.name}`}
      label={props.variant !== "standard" ? props.labelprops.label : ""}
      variant={props.variant}
      sx={{
        "& input": {
          boxShadow: `0px 0px 0px 30px ${
            props.variant !== "standard" ? "white" : "transparent"
          } inset`,
        },
        "& label": {
          color: "gainsboro",
        },
        "& label.Mui-focused": {
          color:
            props.labelprops.hasTouched && props.labelprops.hasErros
              ? "#F97A91"
              : "#FFB800",
          fontWeight: 700,
        },
        "& fieldset": {
          borderRadius: "0.6rem",
        },

        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            color: "red",
            border:
              props.labelprops.hasTouched && props.labelprops.hasErros
                ? "2px solid #F97A91"
                : "2px solid gainsboro",
          },
          "&:hover fieldset": {
            borderColor: "#FFB800",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#FFB800",
            color: "green",
            fontWeight: 700,
          },
        },
      }}
      {...props}
      InputLabelProps={{
        sx: {
          [`&.${inputLabelClasses.shrink}`]: {
            color:
              props.labelprops.hasTouched && props.labelprops.hasErros
                ? "#F97A91"
                : "#000000",
            fontWeight: 700,
            fontSize: "2rem",
            background: "white",
            paddingRight: "0.4rem",
          },
        },
      }}
    />

    {props.type === enumType.EMAIL &&
      props.labelprops.hasTouched &&
      props.labelprops.hasErros && (
        <Typography
          color="tagsAndStatus.2"
          sx={{
            fontSize: "1.2rem",
            marginLeft: "3rem",
            textAlign: "left",
            mb: 1,
          }}
        >
          {props.labelprops.hasErros}
        </Typography>
      )}

    {props.type === enumType.PASSWORD &&
      props.labelprops.hasTouched &&
      props.labelprops.hasErros && (
        <Typography
          color="tagsAndStatus.2"
          sx={{
            fontSize: "1.2rem",
            marginLeft: "3rem",
            textAlign: "left",
          }}
        >
          {props.labelprops.hasErros}
        </Typography>
      )}
  </>
))(({ theme }) => ({
  "& .MuiInput-underline:before": {
    borderBottomColor: alpha(theme.palette.subTitles.main, 0.3),
  },
  "& .MuiInput-input": {
    color: theme.palette.subTitles.main,
  },
}));

export { StyledTextField };
