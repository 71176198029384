import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";

import { ArrowBackIos, Check } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import { NoVisibleIcon, VisibleIcon } from "../../../components/Icons";
import { StyledButton } from "../../../components/StyledButton";
import { StyledTextField } from "../../../components/StyledTextField";

import { routesConfig } from "../../../config/routes";
import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";
import { IAuthService } from "../../../modules/user/models/IAuthService";

import { useContextualNavigate } from "../../../hooks/ContextualNavigations";
import { getSnackbar } from "../../../utils/Snackbars";
import { showPass } from "../../../utils";

export const UpdatePasswordNotPortal: React.FC = () => {
  const navigate = useContextualNavigate();
  const location = useLocation();
  const oldPassword = location.state.password || "";
  const email = location.state.email || "";

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    showOldPassword: false,
    showNewPassword: false,
    email,
    showNewPasswordConfirmation: false,
  });

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowNewPasswordConfirmation = () => {
    setValues({
      ...values,
      showNewPasswordConfirmation: !values.showNewPasswordConfirmation,
    });
  };

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  const [successUpdatePassword, setSuccessUpdatePassword] = useState(false);
  useEffect(() => {}, [successUpdatePassword]);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
    newPass: yup.string().required("Este campo é obrigatório"),
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{ email, newPass: "", newPassConfirmation: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          if (oldPassword === values.newPass) {
            throw new Error(
              "A senha atual não pode ser igual a senha anterior!"
            );
          }
          if (values.newPass !== values.newPassConfirmation) {
            throw new Error("As senhas devem ser iguais.");
          }

          const payload = {
            email: values.email,
            old_password: oldPassword,
            new_password: values.newPass,
          };

          await authService.updatePassword(payload);

          actions.resetForm();
          setSuccessUpdatePassword(true);

          enqueueSnackbar(
            getSnackbar({
              message: "Senha atualizada com sucesso",
              variant: "success",
            })
          );
        } catch (error) {
          setSuccessUpdatePassword(false);

          if ((error?.message).toString().indexOf("not found") !== -1) {
            error.message = "Error: Usuario nao encontrado";
          }

          enqueueSnackbar(
            getSnackbar({
              message: error.message,
              variant: "error",
            })
          );
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid container>
            <Grid
              item
              xs={0}
              md={6}
              sx={{
                height: "100vh",
                backgroundImage: `url(/banner-login.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPositionX: "left",
              }}
            />
            <Grid item xs={12} md={6} px={{ xs: 5, md: 12 }} py={8}>
              <IconButton
                onClick={handleClickGoToBackHome}
                edge="end"
                sx={{ display: "flex" }}
              >
                <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
              </IconButton>

              {successUpdatePassword ? (
                <Box>
                  <Box>
                    <img
                      src="/images/atem-logotipo.png"
                      alt="logotipo"
                      style={{ marginBottom: "2rem" }}
                    />

                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      Senha Atualizada
                    </Typography>

                    <Typography
                      color="subTitles.main"
                      sx={{
                        fontSize: "1.6rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Sua nova senha foi Atualizada com sucesso!
                    </Typography>
                  </Box>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleClickGoToBackHome}
                    loading={props.isSubmitting}
                  >
                    Fazer Login
                  </StyledButton>
                </Box>
              ) : (
                <Box>
                  <Box sx={{ my: 4 }}>
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: "3.5rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      Primeiro Acesso
                    </Typography>

                    <Typography
                      color="subTitles.main"
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.6rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ width: "70%", margin: "0.6rem" }}>
                        {" "}
                        Seu primeiro acesso? Crie uma nova senha para garantir a
                        sua segurança{" "}
                      </p>
                    </Typography>
                  </Box>

                  <Box sx={{ my: 4 }}>
                    <StyledTextField
                      type="email"
                      id="email"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={true}
                      fullWidth
                      variant="standard"
                      sx={{ borderRadius: "1rem", mt: 2 }}
                      labelprops={{
                        label: "Email",
                        hasTouched: Boolean(props.touched.email),
                        hasErros: props.errors.email as string,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" sx={{ m: 0 }}>
                              {props.touched.email && !props.errors.email ? (
                                <Check
                                  sx={{ color: "#0BB873", opacity: "1" }}
                                />
                              ) : (
                                <Check sx={{ color: "black", opacity: "0" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <StyledTextField
                      type={showPass(values.showNewPassword)}
                      name="newPass"
                      value={props.values.newPass}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      fullWidth
                      variant="standard"
                      labelprops={{
                        label: "Nova senha",
                        hasTouched: props.touched.newPass,
                        hasErros: props.errors.newPass,
                      }}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ m: 0 }}
                            >
                              {values.showNewPassword ? (
                                <NoVisibleIcon
                                  sx={{ color: "black", opacity: "0.5" }}
                                />
                              ) : (
                                <VisibleIcon
                                  sx={{ color: "primary.main", opacity: "0.5" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <StyledTextField
                      type={
                        values.showNewPasswordConfirmation ? "text" : "password"
                      }
                      name="newPassConfirmation"
                      value={props.values.newPassConfirmation}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      fullWidth
                      variant="standard"
                      labelprops={{
                        label: "Confirme a nova senha",
                        hasTouched: props.touched.newPassConfirmation,
                        hasErros: props.errors.newPassConfirmation,
                      }}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowNewPasswordConfirmation}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ m: 0 }}
                            >
                              {values.showNewPasswordConfirmation ? (
                                <NoVisibleIcon
                                  sx={{ color: "black", opacity: "0.5" }}
                                />
                              ) : (
                                <VisibleIcon
                                  sx={{ color: "primary.main", opacity: "0.5" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={props.isSubmitting}
                    sx={{ my: 2 }}
                    fullWidth
                  >
                    Trocar Senha
                  </StyledButton>
                </Box>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePasswordNotPortal;
