import { 
  useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  Box,
  IconButton,
  Link,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Check } from "@mui/icons-material";

import { StyledGridContainer } from "../../../../components/StyledLayout";
import { StyledTextField } from "../../../../components/StyledTextField";
import { StyledButton } from "../../../../components/StyledButton";
import { StyledCard } from "../../../../components/StyledCard";
import { NoVisibleIcon, VisibleIcon } from "../../../../components/Icons";

import { routesConfig } from "../../../../config/routes";

import {
  useQueryParams,
} from "../../../../hooks/ContextualNavigations";
import { initialValuesLoginForm } from "../form/initialValues";
import { validationSchemaLoginForm } from "../form/validationSchema";
import { useLoginContext } from "../context/LoginContext";
import { ILoginFormValues } from "../form/interface";

export const DesktopPageView: React.FC = () => {

  const { query } = useQueryParams();
  const { loginContext } = useLoginContext();
  
  const [values, setValues] = useState({
    showPassword: false,
    hasFocus: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleOnChangeHasFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = (event.target as HTMLInputElement).value;

    if (input.length > 0) {
      setValues({
        ...values,
        hasFocus: true,
      });
    } else {
      setValues({
        ...values,
        hasFocus: false,
      });
    }
  };


  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };


  return (
    <Formik
      initialValues={initialValuesLoginForm}
      validationSchema={validationSchemaLoginForm}
      onSubmit={(formValues: ILoginFormValues) => loginContext.fetchLogin(formValues)}
    >
      {(props) => (
        <Form>
          <StyledGridContainer>
            <StyledCard>
              <Box>
                <Typography
                  color="subTitles.main"
                  sx={{ fontSize: "1.6rem", mt: 1 }}
                >
                  Bem vindo
                </Typography>

                <Typography
                  color="primary"
                  sx={{
                    fontSize: "4rem",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    mt: -1,
                  }}
                >
                  Login
                </Typography>
              </Box>

              <Box sx={{ my: 4, p: 4 }}>
                <StyledTextField
                  type="email"
                  id="email"
                  name="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  fullWidth
                  labelprops={{
                    label: "E-mail",
                    hasTouched: props.touched.email,
                    hasErros: props.errors.email,
                  }}
                  sx={{
                    mb: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          {props.touched.email && !props.errors.email ? (
                            <Check sx={{ color: "#0BB873", opacity: "1" }} />
                          ) : (
                            <Check sx={{ color: "black", opacity: "0" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <StyledTextField
                  type={values.showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={props.values.password}
                  onChange={(e) => {
                    props.handleChange(e);
                    handleOnChangeHasFocus(e);
                  }}
                  onBlur={props.handleBlur}
                  fullWidth
                  labelprops={{
                    label: "Senha",
                    hasTouched: props.touched.password,
                    hasErros: props.errors.password,
                  }}
                  sx={{
                    mb: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ m: 0 }}
                        >
                          {values.showPassword ? (
                            <NoVisibleIcon
                              sx={{
                                color: "black",
                                opacity: values.hasFocus ? "1" : "0.2",
                              }}
                            />
                          ) : (
                            <VisibleIcon
                              sx={{
                                color: "primary.main",
                                opacity: values.hasFocus ? "1" : "0.2",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    pr: 3,
                    mt: -1,
                  }}
                >
                  <Link
                    color="primary.main"
                    underline="none"
                    component={LinkRouter}
                    to={`${routesConfig.FORGOT_PASSWORD}${query}`}
                  >
                    Esqueceu a senha?
                  </Link>
                </Box>
              </Box>

              <Box sx={{ my: 6 }}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={props.isSubmitting}
                >
                  Fazer login
                </StyledButton>

                <Link
                  sx={{ fontWeight: "bold", my: 6 }}
                  color="primary.main"
                  underline="none"
                  href="https://atem.com.br/seja-um-revendedor/"
                  target={"_blank"}
                >
                  <span
                    style={{
                      color: "#3E3D3D",
                      opacity: 0.4,
                      fontWeight: "lighter",
                    }}
                  >
                    Ainda não tem conta?
                  </span>{" "}
                  Seja um revendedor
                </Link>
              </Box>
            </StyledCard>
          </StyledGridContainer>
        </Form>
      )}
    </Formik>
  );
};

export default DesktopPageView;
