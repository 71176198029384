import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";

import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { Check, ArrowBackIos  } from '@mui/icons-material';

import { getSnackbar } from "../../../utils/Snackbars";
import AppError from "../../../utils/appError";

import { IAuthService } from "../../../modules/user/models/IAuthService";
import { useIocContext } from "../../../contexts/ioc/IocContext";

import { Types } from "../../../ioc/types";
import { routesConfig } from "../../../config/routes";

import { StyledGridContainer } from "../../../components/StyledLayout";
import { StyledTextField } from "../../../components/StyledTextField";
import { StyledButton } from "../../../components/StyledButton";
import { StyledCard } from "../../../components/StyledCard";
import { useContextualNavigate, useQueryParams } from "../../../hooks/ContextualNavigations";

const ForgotPasswordDesktopView: React.FC = () => {
  const navigate = useContextualNavigate();
  const { queryDict } = useQueryParams();

  const [successSendEmail, setSuccessSendEmail] = useState(false);
  useEffect( () => {} , [successSendEmail] )

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(Types.User.IAuthService);

  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({hasFocus: false});

  const handleOnChangeHasFocus = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const input = (event.target as HTMLInputElement).value;

    if(input.length > 0){
      setValues({
        ...values,
        hasFocus: true,
      });
    }else{
      setValues({
        ...values,
        hasFocus: false,
      });
    }

  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
  });

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`)
  }
  
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={ async (values,action) => {
        try {
          await authService.forgetPassword({ email: values.email, metadata: {url: new URL(queryDict['url']).origin, rules: queryDict['rules']} });
          
          action.resetForm();
          
          setSuccessSendEmail(true);
            
          enqueueSnackbar(
            getSnackbar({
              message: "Email Enviado com sucesso",
              variant: "success",
            })
          );
        } catch (error) {

          setSuccessSendEmail(false);
          
          if (error instanceof AppError) {
            return enqueueSnackbar(
              getSnackbar({ message: error.message, variant: error.variant })
            );
          }else{
            return enqueueSnackbar(
              getSnackbar({
                message: "Não foi possível enviar o email, tente novamente.",
                variant: "error",
              })
            );
          }

        }
      }}
      >
      {(props) => (

        <Form>

          <StyledGridContainer>
            <StyledCard>

              <IconButton onClick={handleClickGoToBackHome} edge="end" sx={{ display: "flex",   }} >
                  <ArrowBackIos sx={{color: "primary.main", opacity: "1"}}  />
              </IconButton>

              {successSendEmail ? 
              
                <Box>
                  
                  <img src="/images/atem-logotipo.png" alt="logotipo" style={{marginBottom: "4rem"}}/>
                  
                  <Typography
                      color="primary"
                      sx={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                    >
                      Confira seu e-mail
                  </Typography>

                  <Typography  color="subTitles.main" sx={{ fontWeight: 500, fontSize: "1.6rem", display: "flex", justifyContent: "center" }}> 
                    Nós enviamos instruções sobre uma nova senha para o seu e-mail
                  </Typography>

                </Box>
              
              :

                <Box>
                  <Box sx={{my:4, mx:4}}>

                    <Typography
                        color="primary"
                        sx={{
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        Digite seu e-mail
                    </Typography>

                    <Typography  color="subTitles.main" sx={{ fontWeight: 500, fontSize: "1.6rem", display: "flex", justifyContent: "center" }}> 
                      Enviaremos instruções para a redefinição da sua senha
                    </Typography>

                  </Box>

                  <Box sx={{my: 4, mx: 4}}>

                    <StyledTextField
                      type="email"
                      id="email"
                      name="email"
                      value={props.values.email}
                      onChange={ (e) => { props.handleChange(e); handleOnChangeHasFocus(e); }} 
                      onBlur={props.handleBlur}
                      labelprops={{ label: "Email" , hasTouched: props.touched.email, hasErros: props.errors.email  }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton 
                              edge="end" 
                              sx={{m:0}}
                            >
                              { props.touched.email && !props.errors.email ? (
                                <Check sx={{color: "#0BB873", opacity: "1"}}  />
                              ) : (
                                <Check sx={{color: "black", opacity:"0" }}  />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    /> 
                  </Box>

                  <StyledButton variant="contained" color="primary" type="submit" loading={props.isSubmitting} 
                    sx={{ px: 4, my: 2 }}>
                    Continuar
                  </StyledButton>
                </Box> 

              }

            </StyledCard>
          </StyledGridContainer> 

        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordDesktopView;
