export interface FormikValuesUpdatePassword {
  email: string;
  newPass: string;
  newPassConfirmation: string;
}

export enum UpdatePasswordFormFields {
  email="email",
  newPass="newPass",
  newPassConfirmation="newPassConfirmation",
}
