import { Paper, Typography } from "@mui/material";
import { StyledIcons } from "../components/StyledIcons";

export interface GetSnackbarProps {
  message: string;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info';
}

export const getSnackbar = (data: GetSnackbarProps) => {
  switch (data.variant) {
    case "error": {
      return (
        <Paper
          sx={{
            backgroundColor: "#F77B92", // theme.palette.tagsAndStatus[2]
            width: "100%",
            px: 2,
            py: 1,
            borderRadius: "0.4rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: (theme) => "#FFF5F7", // theme.palette.tagsAndStatus[5]
              ml: 1,
              fontSize: "1.4rem",
            }}
          >
            {data.message}
          </Typography>
        </Paper>
      );
    }
    case "success": {
      return (
        <Paper
          sx={{
            backgroundColor: (theme) => "#0BB873", //  theme.palette.tagsAndStatus[0]
            width: "100%",
            px: 2,
            py: 1,
            borderRadius: "0.4rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <StyledIcons
            iconType="success"
            iconColor="#fff"
            sx={{ width: "1rem", height: "1rem" }}
          />
          <Typography
            sx={{
              color: (theme) => "#FFF5F7", //  theme.palette.tagsAndStatus[5]
              ml: 1,
              fontSize: "1.4rem",
            }}
          >
            {data.message}
          </Typography>
        </Paper>
      );
    }
  }
};
