import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import * as yup from "yup";
import { Buffer } from 'buffer';

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";

import { Check } from "@mui/icons-material";

import { NoVisibleIcon, VisibleIcon } from "../../../components/Icons";
import { StyledButton } from "../../../components/StyledButton";
import { StyledTextField } from "../../../components/StyledTextField";

import config from "../../../config/default";
import { routesConfig } from "../../../config/routes";

import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";
import {
  AuthState,
  IAuthService,
} from "../../../modules/user/models/IAuthService";

import {
  useContextualNavigate,
  useQueryParams,
} from "../../../hooks/ContextualNavigations";
import { UserStatus } from "../../../modules/user/domain/constants";
import { getSnackbar } from "../../../utils/Snackbars";
import AppError from "../../../utils/appError";

export const LoginNotPortalPage: React.FC = () => {
  let navigate = useContextualNavigate();

  const urlString = window.location.href; // Pega o Url inteira, EX: http://localhost:3001/?url=http://localhost:3000/#/dashboard&rules=supply-chain
  if (urlString.indexOf("?") === -1) {
    throw new Error("Ausência de Parâmetros na Url");
  }

  const paramString = urlString.split("?")[1]; // Pega apenas a url Parametro, Ex: url=http://localhost:3000/#/dashboard&rules=supply-chain
  const queryString = new URLSearchParams(paramString); // Transforma em objeto chave e valor

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    showPassword: false,
    hasFocus: false,
  });

  const { query } = useQueryParams();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleOnChangeHasFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = (event.target as HTMLInputElement).value;

    if (input.length > 0) {
      setValues({
        ...values,
        hasFocus: true,
      });
    } else {
      setValues({
        ...values,
        hasFocus: false,
      });
    }
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Insira um e-mail válido")
      .required("Este campo é obrigatório"),
    password: yup.string().required("Este campo é obrigatório"),
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [initialEmail, initialPassword] = (() => {
    const rawToken = queryString.get("token");
    if (rawToken) {
      return Buffer.from(rawToken, "base64").toString("utf-8").split(' ') as [string, string];
    }

    return ["", ""]
  })();

  return (
    <Formik
      initialValues={{ email: initialEmail, password: initialPassword, rules: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {


          if (!queryString.get("url")) {
            throw new Error(
              "Ausência de Parâmetros na Url - link de Redirecionamento (url off)"
            );
          }
          const redirectURL = queryString.get("url");

          if (!queryString.get("rules")) {
            throw new Error(
              "Ausência de Parâmetros na Url - regras do projeto (rules off) "
            );
          }
          const rules = queryString.get("rules");

          values.rules = rules;

          const loginResponse = await authService.authLogin(values);

          if (loginResponse.state === AuthState.SENT) {
            return navigate(routesConfig.SECURITY_AUTH, {
              state: {
                email: values.email,
                password: values.password,
              },
            });
          }

          if (loginResponse.state === AuthState.FAILED_SENT) {
            throw new Error(
              "Tivemos um problema ao enviar o link de verificação para seu email"
            );
          }

          if (
            loginResponse.state === AuthState.FIRST_ACCESS ||
            loginResponse.meta.custom_attributes.userStatus ===
            UserStatus.FORCE_CHANGE_PASSWORD
          ) {
            const extendedPassword = loginResponse.extendedPassword;
            return navigate(`${routesConfig.UPDATE_PASSWORD_FIRST_ACCESS}`, {
              state: {
                password: extendedPassword || values.password,
                email: values.email,
                migration: Boolean(extendedPassword),
              },
            });
          }

          if (
            loginResponse.meta.custom_attributes.userStatus !==
            UserStatus.CONFIRMED
          ) {
            return navigate(`${routesConfig.NEW_PASSWORD()}`);
          }

          console.log("successfully Logged In.");

          const cookies = new Cookies();
          cookies.set("accessToken", loginResponse.AccessToken, {
            path: "/",
            domain: config.domain,
          });
          cookies.set("refreshToken", loginResponse.RefreshToken, {
            path: "/",
            domain: config.domain,
          });

          document.location.href = redirectURL; // A url precisa receber o protocolo http para poder funcionar -> https://example.com
        } catch (error) {
          if (error as AppError) {
            enqueueSnackbar(
              getSnackbar({
                message: error.message,
                variant: "error",
              })
            );
          }
        }
      }}
    >
      {(props) => (
        <Form>
          <Grid container>
            <Grid
              item
              xs={0}
              md={6}
              sx={{
                height: "100vh",
                backgroundImage: `url(/banner-login.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPositionX: "left",
              }}
            />
            <Grid item xs={12} md={6} px={{ xs: 5, md: 12 }} py={8}>
              <Box>
                <Typography
                  color="subTitles.main"
                  sx={{ fontSize: "1.6rem", mt: 1 }}
                >
                  Bem vindo
                </Typography>

                <Typography
                  color="primary"
                  sx={{
                    fontSize: "4rem",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    mt: -1,
                  }}
                >
                  Fazer login
                </Typography>
              </Box>

              <Box mt={12}>
                <StyledTextField
                  type="email"
                  id="email"
                  name="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  fullWidth
                  variant="standard"
                  placeholder="Digite o email"
                  labelprops={{
                    label: "E-mail",
                    hasTouched: props.touched.email,
                    hasErros: props.errors.email,
                  }}
                  sx={{
                    mb: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          {props.touched.email && !props.errors.email ? (
                            <Check sx={{ color: "#0BB873", opacity: "1" }} />
                          ) : (
                            <Check sx={{ color: "black", opacity: "0" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <StyledTextField
                  type={values.showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Digite a senha"
                  value={props.values.password}
                  onChange={(e) => {
                    props.handleChange(e);
                    handleOnChangeHasFocus(e);
                  }}
                  onBlur={props.handleBlur}
                  fullWidth
                  variant="standard"
                  labelprops={{
                    label: "Senha",
                    hasTouched: props.touched.password,
                    hasErros: props.errors.password,
                  }}
                  sx={{
                    mb: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          sx={{ m: 0 }}
                        >
                          {values.showPassword ? (
                            <NoVisibleIcon
                              sx={{
                                color: "black",
                                opacity: values.hasFocus ? "1" : "0.2",
                              }}
                            />
                          ) : (
                            <VisibleIcon
                              sx={{
                                color: "primary.main",
                                opacity: values.hasFocus ? "1" : "0.2",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    mb: 5,
                  }}
                >
                  <Link
                    color="primary.main"
                    underline="none"
                    component={LinkRouter}
                    to={`${routesConfig.FORGOT_PASSWORD}${query}`}
                  >
                    Esqueceu a senha?
                  </Link>
                </Box>
              </Box>

              <Box>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={props.isSubmitting}
                  fullWidth
                >
                  Entrar
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginNotPortalPage;
