import { useEffect, useState } from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useColor } from "../../hooks/useColor";

interface StrengthOptions {
  isStrong: boolean;
  label: string;
}

const passwordCriteria = {
  hasNumber: /\d/,
  hasUppercase: /[A-Z]/,
  hasLowercase: /[a-z]/,
  hasSymbol: /[!@#$%^&*(),.?":{}|<>_]/,
  minLength: 8,
  strongLength: 12,
};

const getPasswordStrength = (password: string): number => {
  const { hasNumber, hasUppercase, hasLowercase, hasSymbol, minLength, strongLength } = passwordCriteria;

  const lengthTest = password.length >= minLength;
  const strongLengthTest = password.length >= strongLength;

  const numCriteriaMet = [hasNumber, hasUppercase, hasLowercase, hasSymbol]
  .filter((regex) => regex.test(password))
  .length;

  const allCriteriaBonus = Number(numCriteriaMet >= 3 && lengthTest);

  return numCriteriaMet + allCriteriaBonus + Number(lengthTest) + Number(strongLengthTest);
};

const updateStrongOptions = (
  password: string,
  confirmPassword: string
): StrengthOptions[] => {
  return [
    {
      isStrong: passwordCriteria.hasNumber.test(password),
      label: "Número",
    },
    {
      isStrong: passwordCriteria.hasUppercase.test(password),
      label: "Letra Maiúscula",
    },
    {
      isStrong: passwordCriteria.hasLowercase.test(password),
      label: "Letra Minúscula",
    },
    {
      isStrong: passwordCriteria.hasSymbol.test(password),
      label: "Símbolo",
    },
    {
      isStrong: password.length >= passwordCriteria.minLength,
      label: "Pelo menos 8 caracteres",
    },
    {
      isStrong: password === confirmPassword && confirmPassword.length > 0,
      label: "Senhas conferem",
    },
  ];
};

enum strengthLevels {
  lowPass = "Senha Fraca",
  middlePass = "Senha Média",
  strongPass = "Senha Forte",
}

const PasswordStrengthBar: React.FC<{
  newPass: string;
  newPassConfirmation: string;
}> = ({ newPass, newPassConfirmation }) => {
  const { red, yellow, green, gray, subTitles } = useColor();

  const [strength, setStrength] = useState<number>(0);
  const [strongOptions, setStrongOptions] = useState<StrengthOptions[] | null>(null);

  useEffect(() => {
    const handleSetStrength = () => {
      setStrength(getPasswordStrength(newPass));
    };

    const handleSetOptions = () => {
      setStrongOptions(updateStrongOptions(newPass, newPassConfirmation));
    };

    handleSetStrength();
    handleSetOptions();
  }, [newPass, newPassConfirmation]);

  const applyColor = (strength: number) => {
    if (strength <= 2) return red; 
    if (strength >= 3 && strength <= 4) return yellow;
    if (strength >= 5) return green; 
  };

  const getStrengthLevels = (strength: number) => {
    if (strength <= 2) return strengthLevels.lowPass;
    if (strength >= 3 && strength <= 4) return strengthLevels.middlePass;
    if (strength >= 5) return strengthLevels.strongPass;
  };

  return (
    <Grid container gap={1}>
      <Grid item xs={12}>
        <Grid container className="strong-bar-pass" gap={1.45} justifyContent={"center"} sx={{ opacity: 0.6, flexWrap:"nowrap" }}>
          {Array.from({ length: 7 }).map((_, index) => {
            return (
              <Grid item key={index} xs={1.43}  >
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    borderRadius: 2,
                    height: "6px",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor:
                        index < strength ? applyColor(index) : gray,       
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant={"caption"} sx={{ color: applyColor(strength-1) }}>
          {getStrengthLevels(strength-1)}
        </Typography>
        <Typography variant={"caption"}> Min. 8 Caracteres </Typography>
      </Grid>

      <Grid item xs={12} >
        {strongOptions?.map((option, index) => {
          return (
            <Grid container key={index} alignItems={"center"} gap={0.5}>
              <Grid item sx={{ pt: 1 }}>
                {option.isStrong ? (
                  <CheckIcon sx={{ color: green }} />
                ) : (
                  <CloseIcon sx={{ color: red }} />
                )}
              </Grid>
              <Grid item>
                <Typography variant={"caption"} sx={{ fontWeight:500, color: subTitles }}> {option.label} </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export { PasswordStrengthBar };