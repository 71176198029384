import useMedia from "use-media";

import { LoginProvider } from "./context/LoginContext";
import DesktopPageView from "./view/DesktopPageView";
import MobilePageView from "./view/MobilePageView";

function LoginDefault() {
  const isMobile = useMedia({ maxWidth: 650 });

  return (
    <LoginProvider>
      {isMobile ? <MobilePageView /> : <DesktopPageView />}
    </LoginProvider>
  )
}

export default LoginDefault;
