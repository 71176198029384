import { useCurrentURL } from "../hooks/ContextualNavigations";
import { AtemApplication } from "../modules/user/domain/constants";

const applicationList = {
  suprimentos: AtemApplication.SUPPLY_CHAIN,
  comercial: AtemApplication.SALES_PORTAL,
  portal: AtemApplication.CUSTOMER_PORTAL,
  checklist: AtemApplication.CHECKLIST,
  carregamento: AtemApplication.LOADING,
  vendas: AtemApplication.SALES,
};

export const CurrentApplication = () => {
  const params = useCurrentURL();

  const subdomain = params.url.replace(/^https?:\/\//, "").split(".")[0];

  for (const item of Object.keys(applicationList)) {
    if (subdomain.includes(item)) {
      return applicationList[item] as AtemApplication;
    }
  }
  return AtemApplication.CUSTOMER_PORTAL;
};

export const showPass = (showPass: boolean) => {
  return showPass ? "text" : "password";
}
