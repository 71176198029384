import Cookies from "universal-cookie";
import config from '../../../../config/default';

export const validateUrlParameters = (queryString: URLSearchParams) => {

  const redirectURL = queryString.get("url");
  const rules = queryString.get("rules");

  if (!redirectURL) {
    throw new Error("Ausência de Parâmetros na Url - link de Redirecionamento (url off)");
  }

  if (!rules) {
    throw new Error("Ausência de Parâmetros na Url - regras do projeto (rules off)");
  }

  return { redirectURL, rules };
};

export const setCookiesRefreshToken = (RefreshToken: string) => {
  const cookies = new Cookies();
  cookies.set("refreshToken", RefreshToken, {
    path: "/",
    domain: config.domain,
  });
};

export const setCookiesAccessToken = (AccessToken: string) => {
  const cookies = new Cookies();
  cookies.set("accessToken", AccessToken, {
    path: "/",
    domain: config.domain,
  });
};