import * as yup from "yup";
import { strongPasswordValidation } from "../../../../utils/passwordValidation";

export const validationSchemaUpdatePassword = yup.object().shape({
  email: yup
    .string()
    .email("Insira um e-mail válido")
    .required("Este campo é obrigatório"),
  newPass: strongPasswordValidation,
  newPassConfirmation: yup
    .string()
    .oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais.')
    .required("Este campo é obrigatório"),
});