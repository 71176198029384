import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  useQueryParams,
} from "../../../../hooks/ContextualNavigations";

import { useHeight } from "../../../../hooks/Height";

import { routesConfig } from "../../../../config/routes";

import { StyledTextField } from "../../../../components/StyledTextField";
import { StyledButton } from "../../../../components/StyledButton";
import { initialValuesLoginForm } from '../form/initialValues';
import { validationSchemaLoginForm } from "../form/validationSchema";
import { ILoginFormValues } from "../form/interface";
import { useLoginContext } from "../context/LoginContext";
import { useColor } from "../../../../hooks/useColor";

export const MobilePageView: React.FC = () => {
  const { loginContext } = useLoginContext();
  const { primary } = useColor();

  const height = useHeight();
  const theme = useTheme();
  const { query } = useQueryParams();

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={initialValuesLoginForm}
      validationSchema={validationSchemaLoginForm}
      onSubmit={(formValues: ILoginFormValues) => loginContext.fetchLogin(formValues)}
    >
      {(props) => (
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: height },
              backgroundImage: `url(/image-login-background-laptop.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Box sx={{ width: "20%", mb: 10 }}>
                <img alt="Logo ATEM" src="/images/logo.svg" />
              </Box>
            </Box>

            <Card
              sx={{
                borderRadius: "1.6rem",
                width: "90%",
                display: "flex",
                margin: "0 auto",
              }}
              component={motion.div}
              initial={{ y: document.documentElement.clientHeight }}
              animate={{ y: 0 }}
              transition={{
                duration: theme.transitions.duration.complex / 1000,
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} justifyContent={"center"}>
                    <Typography
                      color="subTitles.main"
                      sx={{
                        fontSize: { xs: "1.6rem", md: "4rem" },
                        textAlign: "center",
                      }}
                    >
                      Bem vindo
                    </Typography>
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: { xs: "3.2rem" },
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      Fazer login
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} mt={8}>
                    <Grid item xs={12}>
                      <StyledTextField
                        type="email"
                        id="email"
                        name="email"
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        fullWidth
                        placeholder="Digite o e-mail cadastrado"
                        labelprops={{ label: "E-mail" }}
                      />
                      {props.touched.email && props.errors.email && (
                        <div style={{ color: primary, fontSize: "1.2rem" }}>
                          {props.errors.email}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} mt={3}>
                      <StyledTextField
                        type={values.showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={props.values.password}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        fullWidth
                        placeholder="Digite a sua senha"
                        labelprops={{ label: "Senha" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {props.touched.password && props.errors.password && (
                        <div style={{ color: primary, fontSize: "1.2rem" }}>
                          {props.errors.password}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "right" }} mt={2}>
                      <Link
                        color="primary.main"
                        underline="none"
                        component={LinkRouter}
                        to={`${routesConfig.FORGOT_PASSWORD}${query}`}
                      >
                        Esqueceu a senha?
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    rowSpacing={0}
                    spacing={0}
                  >
                    <Grid item xs={10}>
                      <StyledButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={props.isSubmitting}
                      >
                        Fazer login
                      </StyledButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <span
                        style={{
                          color: "#3E3D3D",
                          fontWeight: 400,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Ainda não tem uma conta?
                      </span>{" "}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Link
                        color="primary.main"
                        underline="none"
                        href="https://atem.com.br/seja-um-revendedor/"
                        target={"_blank"}
                        style={{ fontWeight: "bold" }}
                      >
                        Seja um revendedor!
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MobilePageView;
