import { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";

import { ArrowBackIos } from "@mui/icons-material";
import { Grid, Box, IconButton, InputAdornment, Typography } from "@mui/material";

import { StyledGridContainer } from "../../components/StyledLayout";
import { NoVisibleIcon, VisibleIcon } from "../../components/Icons";
import { StyledTextField } from "../../components/StyledTextField";
import { StyledButton } from "../../components/StyledButton";
import { StyledCard } from "../../components/StyledCard";

import { QueryTypesNewPassword, routesConfig } from "../../config/routes";
import { IAuthService } from "../../modules/user/models/IAuthService";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { Types } from "../../ioc/types";

import { useContextualNavigate } from "../../hooks/ContextualNavigations";
import { useQuery } from "../../hooks/Query";

import { getSnackbar } from "../../utils/Snackbars";
import AppError from "../../utils/appError";

import { initialValuesFormikUpdatePassword } from "./form/initialValues";
import { ChangePasswordFormFields, FormikValuesChangePassword } from "./form/interfaceValues";
import { PasswordStrengthBar } from "../../components/PasswordStrengthBar";
import { validationSchemaChangePassword } from "./form/validationSchema";

const ChangePassword: React.FC = () => {
  const query = useQuery<QueryTypesNewPassword>();
  let navigate = useContextualNavigate();

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const handleClickGoToBackHome = () => {
    navigate(`${routesConfig.LOGIN}`);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
    hasFocus: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleOnChangeHasFocus = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = (event.target as HTMLInputElement).value;

    if (input.length > 0) {
      setValues({
        ...values,
        hasFocus: true,
      });
    } else {
      setValues({
        ...values,
        hasFocus: false,
      });
    }
  };

  const [successSendEmail, setSuccessSendEmail] = useState(false);
  useEffect(() => {}, [successSendEmail]);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSubmit = async (
    values: FormikValuesChangePassword,
    actions: FormikHelpers<FormikValuesChangePassword>
  ) => {
    try {

      const emailParameter = query.get("email") as string;
      if (!emailParameter) {
        throw new AppError("O email precisa ser informado no parametro da url", "error");
      }

      const codeParameter = query.get("verification_code") as string;
      if (!codeParameter) {
        throw new AppError("O codigo de confirmacao precisa ser informado no parametro da url","error");
      }

      await authService.changePassword({
        email: emailParameter,
        password: values.confirmPass,
        confirmation_code: codeParameter,
      });

      actions.resetForm();

      setSuccessSendEmail(true);

      enqueueSnackbar(
        getSnackbar({
          message: "Senha atualizada com sucesso",
          variant: "success",
        })
      );
    } catch (error) {
      setSuccessSendEmail(false);

      if (error instanceof AppError) {
        return enqueueSnackbar(
          getSnackbar({ message: error.message, variant: error.variant })
        );
      } else {
        return enqueueSnackbar(
          getSnackbar({
            message: `Não foi possível enviar o email, tente novamente.`,
            variant: "error",
          })
        );
      }
    }
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValuesFormikUpdatePassword}
      validationSchema={validationSchemaChangePassword}
      onSubmit={handleSubmit}
    >
      {(propsFormik) => {
        const { values: formValues, isValid, isSubmitting, touched, errors, handleChange, handleBlur } = propsFormik;
        return(
          <Form>
            <StyledGridContainer>
              <StyledCard>
                <IconButton
                  onClick={handleClickGoToBackHome}
                  edge="end"
                  sx={{ display: "flex" }}
                >
                  <ArrowBackIos sx={{ color: "primary.main", opacity: "1" }} />
                </IconButton>
  
                {successSendEmail ? (
                  <Box>
                    <Box>
                      <img
                        src="/images/atem-logotipo.png"
                        alt="logotipo"
                        style={{ marginBottom: "2rem" }}
                      />
  
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        Senha Cadastrada
                      </Typography>
  
                      <Typography
                        color="subTitles.main"
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.6rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Sua nova senha foi cadastrada com sucesso!
                      </Typography>
                    </Box>
  
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={handleClickGoToBackHome}
                      loading={isSubmitting}
                    >
                      Fazer Login
                    </StyledButton>
                  </Box>
                ) : (
                  <Box>
                    <Box sx={{ my: 4 }}>
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "3rem",
                          fontWeight: "bold",
                          fontFamily: "Poppins",
                        }}
                      >
                        Esqueceu a senha?
                      </Typography>
                    </Box>
  
                    <Box sx={{ my: 6, mx: { xs: 0, md: 4 } }}>
                      <Grid container sx={{ gap:{xs:1,lg:2} }}>
                        <Grid item xs={12}>
                          <StyledTextField
                            name={ChangePasswordFormFields.newPass}
                            value={formValues.newPass}
                            type={values.showPassword ? "text" : "password"}
                            onChange={(e) => {
                              handleChange(e);
                              handleOnChangeHasFocus(e);
                            }}
                            onBlur={handleBlur}
                            labelprops={{
                              label: "Nova senha",
                              hasTouched: touched.newPass,
                              hasErros: errors.newPass,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    sx={{ m: 0 }}
                                  >
                                    {values.showPassword ? (
                                      <NoVisibleIcon
                                        sx={{
                                          color: "black",
                                          opacity: values.hasFocus ? "1" : "0.2",
                                        }}
                                      />
                                    ) : (
                                      <VisibleIcon
                                        sx={{
                                          color: "primary.main",
                                          opacity: values.hasFocus ? "1" : "0.2",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextField
                            name={ChangePasswordFormFields.confirmPass}
                            value={formValues.confirmPass}
                            type={values.showConfirmPassword ? "text" : "password"}
                            onChange={(e) => {
                              handleChange(e);
                              handleOnChangeHasFocus(e);
                            }}
                            onBlur={handleBlur}
                            labelprops={{
                              label: "Confirme a nova senha",
                              hasTouched: touched.confirmPass,
                              hasErros: errors.confirmPass,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    sx={{ m: 0 }}
                                  >
                                    {values.showConfirmPassword ? (
                                      <NoVisibleIcon
                                        sx={{
                                          color: "black",
                                          opacity: values.hasFocus ? "1" : "0.2",
                                        }}
                                      />
                                    ) : (
                                      <VisibleIcon
                                        sx={{
                                          color: "primary.main",
                                          opacity: values.hasFocus ? "1" : "0.2",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PasswordStrengthBar
                            newPass={formValues.newPass}
                            newPassConfirmation={formValues.confirmPass}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    
                    <StyledButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={isSubmitting}
                      disabled={!isValid}
                    >
                      Alterar Senha
                    </StyledButton>
                  </Box>
                )}
              </StyledCard>
            </StyledGridContainer>
          </Form>
        )
      }}
    </Formik>
  );
};

export default ChangePassword;
