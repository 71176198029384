import { Box, Typography } from "@mui/material";
import { StyledButton } from "../../../../components/StyledButton";

interface SucessPasswordMessageProps {
  onClick: () => void;
  isSubmitting: boolean;
}

const SucessPasswordMessage: React.FC<SucessPasswordMessageProps> = (props) => {
  const { onClick, isSubmitting } = props;
  const handleClickGoToBackHome = onClick;
  return (
    <Box>
      <Box>
        <img
          src="/images/atem-logotipo.png"
          alt="logotipo"
          style={{ marginBottom: "2rem" }}
        />

        <Typography
          color="primary"
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            fontFamily: "Poppins",
          }}
        >
          Senha Atualizada
        </Typography>

        <Typography
          color="subTitles.main"
          sx={{
            fontSize: "1.6rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              width: "70%",
              margin: "0.6rem",
              marginBottom: "4rem",
            }}
          >
            {" "}
            Sua nova senha foi Atualizada com sucesso!{" "}
          </span>
        </Typography>
      </Box>

      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleClickGoToBackHome}
        loading={isSubmitting}
      >
        Fazer Login
      </StyledButton>
    </Box>
  );
};

export default SucessPasswordMessage;
