import { createContext, useContext, useEffect } from 'react';

import { AuthState, IAuthService } from '../../../../modules/user/models/IAuthService';
import { UserStatus } from '../../../../modules/user/domain/constants';

import { useContextualNavigate } from '../../../../hooks/ContextualNavigations';
import useDialogAlert from '../../../../hooks/useDialogAlert';

import { routesConfig } from '../../../../config/routes';
import AppError from '../../../../utils/appError';

import { useIocContext } from '../../../../contexts/ioc/IocContext';
import { Types } from '../../../../ioc/types';

import { setCookiesAccessToken, setCookiesRefreshToken, validateUrlParameters } from './utilsLoginContext';
import { ILoginFormValues } from '../form/interface';

interface ILoginContext {
  loginContext:{
    fetchLogin: (values) => void;
  }
}
interface ILoginProvider {
  children?: React.ReactNode;
}

const LoginContext = createContext<ILoginContext>(undefined);
const LoginProvider: React.FC<ILoginProvider> = ({ children }) => {

  const { snackbar } = useDialogAlert();
  const navigate = useContextualNavigate();
  
  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(Types.User.IAuthService);

  const urlString = window.location.href;
  const paramString = urlString?.split("?")?.[1];
  const queryString = new URLSearchParams(paramString);

  const fetchLogin = async (values: ILoginFormValues) => {
    try {
      const { redirectURL, rules } = validateUrlParameters(queryString);
      values.rules = rules;

      const loginResponse = await authService.authLogin(values);

      // Note: Verificar se loginResponse possui os atributos esperados, fornecendo valores padrão quando necessário
      const AccessToken = loginResponse?.AccessToken || null;
      const RefreshToken = loginResponse?.RefreshToken || null;
      const state = loginResponse?.state || null;
      const extendedPassword = loginResponse?.extendedPassword || null;

      const userStatus = loginResponse?.meta?.custom_attributes?.userStatus || null; 

      if (state === AuthState.SENT) {
        return navigate(routesConfig.SECURITY_AUTH, {
          state: {
            email: values.email,
            password: values.password,
          },
        });
      }

      if (state === AuthState.FAILED_SENT) {
        throw new Error("Tivemos um problema ao enviar o link de verificação para seu email");
      }

      if (state === AuthState.FIRST_ACCESS || userStatus === UserStatus.FORCE_CHANGE_PASSWORD) {
        return navigate(`${routesConfig.UPDATE_PASSWORD_FIRST_ACCESS}`, {
          state: {
            password: extendedPassword || values.password,
            email: values.email,
            migration: Boolean(extendedPassword),
          },
        });
      }

      if (userStatus !== UserStatus.CONFIRMED) {
        return navigate(`${routesConfig.NEW_PASSWORD()}`);
      }

      console.info("successfully Logged In.");
      setCookiesAccessToken(AccessToken);
      setCookiesRefreshToken(RefreshToken);

      document.location.href = redirectURL; // NOTE: A url precisa receber o protocolo http para poder funcionar -> https://example.com
    } catch (error) {
      if (error as AppError) {
        snackbar({
          message: error.message,
          variant: "error",
        });
      }
    }
  }

  useEffect(() => {
    if (urlString?.indexOf("?") === -1) {
      snackbar({
          message: "Ausência de Parâmetros na Url",
          variant: "error",
        }
      );
    }
  }, [urlString, snackbar]); 
  
  const loginContext = {
    fetchLogin,
  }

  return (
    <LoginContext.Provider value={{
      loginContext
    }}>
      { children }
    </LoginContext.Provider>
  );
}

const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('usePrice deve ser utilizado dentro de um LoginProvider');
  }
  return context;
};

export { LoginProvider, useLoginContext };