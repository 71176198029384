import { SharedProps, VariantType, useSnackbar } from "notistack";
import { getSnackbar } from "../utils/Snackbars";

export interface SnackbarProps extends SharedProps {
  message: string;
  variant: VariantType;
}

const useDialogAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (props: SnackbarProps) => {
    const { message, variant, ...rest } = props;
    enqueueSnackbar(
      getSnackbar({
          message,
          variant, 
        }), rest
     );
  };

  return { snackbar };
};

export default useDialogAlert;