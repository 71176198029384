import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";

import {
  useContextualNavigate,
  useNavigationCookies,
} from "../../../hooks/ContextualNavigations";
import {
  AuthState,
  IAuthLoginDTO,
  IAuthService,
} from "../../../modules/user/models/IAuthService";

import { useIocContext } from "../../../contexts/ioc/IocContext";
import { Types } from "../../../ioc/types";

import { getSnackbar } from "../../../utils/Snackbars";
import AppError from "../../../utils/appError";

import SecurityAuthCard from "./SecurityAuthCard";

import { routesConfig } from "../../../config/routes";

export const SecurityAuthNotPortal: React.FC = () => {
  let navigate = useContextualNavigate();
  let navigateCookies = useNavigationCookies();

  const location = useLocation();

  if (location.state) {
    var { email, password } = location.state as IAuthLoginDTO;
  }

  const iocContext = useIocContext();
  const authService = iocContext.serviceContainer.get<IAuthService>(
    Types.User.IAuthService
  );

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object().shape({
    mfaToken: yup.number().required("Este campo é obrigatório"),
  });

  const handleSubmit = async (
    values: IAuthLoginDTO,
    actions: FormikHelpers<IAuthLoginDTO>
  ) => {
    try {
      const urlString = window.location.href;
      if (urlString.indexOf("?") === -1) {
        throw new Error("Ausência de Parâmetros na Url");
      }

      const paramString = urlString.split("?")[1];

      const queryString = new URLSearchParams(paramString);

      if (!queryString.get("url")) {
        throw new Error(
          "Ausência de Parâmetros na Url - link de Redirecionamento (url off)"
        );
      }
      const redirectURL = queryString.get("url");

      if (!queryString.get("rules")) {
        throw new Error(
          "Ausência de Parâmetros na Url - regras do projeto (rules off) "
        );
      }
      const rules = queryString.get("rules");

      const loginResponse = await authService.authLogin(values);
      const isAutorized = loginResponse.meta.groups.includes(rules);

      if (loginResponse.state === AuthState.TOO_MANY_ACCESSES) {
        throw new Error("Muitas tentativas de acesso");
      }

      if (loginResponse.state === AuthState.EXHAUSTED_ATTEMPTS) {
        throw new Error(
          "Muitas tentativas de acesso, aguarde um momento e tente novamente"
        );
      }

      if (loginResponse.state === AuthState.MUST_START_CHALLENGE) {
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Aguarda 5 segundos
        navigate(routesConfig.LOGIN);
        throw new Error("Código expirado, tente novamente!");
      }

      if (loginResponse.state === AuthState.INCORRECT_TOKEN) {
        throw new Error("Codigo Incorreto, Tente Novamente!");
      }

      if (isAutorized) {
        navigateCookies({
          accessToken: loginResponse.AccessToken,
          refreshToken: loginResponse.RefreshToken,
          redirectURL,
        });
      } else {
        enqueueSnackbar(
          getSnackbar({
            message:
              "Você não possui permissões, entre em contato com um administrador!",
            variant: "error",
          })
        );
        navigate(routesConfig.LOGIN);
      }
    } catch (error) {
      if (error as AppError) {
        enqueueSnackbar(
          getSnackbar({
            message: error.message,
            variant: "error",
          })
        );
      }
    }
  };

  const handleResendCode = async () => {
    try {
      const urlString = window.location.href;
      if (urlString.indexOf("?") === -1) {
        throw new Error("Ausência de Parâmetros na Url");
      }

      const paramString = urlString.split("?")[1];

      const queryString = new URLSearchParams(paramString);

      if (!queryString.get("url")) {
        throw new Error(
          "Ausência de Parâmetros na Url - link de Redirecionamento (url off)"
        );
      }

      await authService.authLogin({ email, password });

      enqueueSnackbar(
        getSnackbar({
          message: "Código enviado com sucesso",
          variant: "success",
        })
      );
    } catch (error) {
      enqueueSnackbar(
        getSnackbar({
          message: error.message,
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!location.state || !email || !password) {
      navigate(routesConfig.LOGIN);
    }
  }, [email, location.state, navigate, password]);

  return (
    <Formik
      initialValues={{ email, password, mfaToken: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form style={{ border: "0px solid red" }}>
          <SecurityAuthCard
            formikProps={props}
            email={email}
            onResendCode={handleResendCode}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SecurityAuthNotPortal;
