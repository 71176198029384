import React from "react";
import { FormikProps } from "formik";

import { Check } from "@mui/icons-material";
import {
  Grid,
  Box,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import { PasswordStrengthBar } from "../../../../components/PasswordStrengthBar";
import { NoVisibleIcon, VisibleIcon } from "../../../../components/Icons";
import { StyledTextField } from "../../../../components/StyledTextField";
import { StyledButton } from "../../../../components/StyledButton";

import { FormikValuesUpdatePassword, UpdatePasswordFormFields } from "../form/interfaceValues";
import { usePasswordVisibility } from "../hook/usePasswordVisibility";
import { useColor } from "../../../../hooks/useColor";
import { showPass } from "../../../../utils";

interface FirstAccessPasswordProps
  extends FormikProps<FormikValuesUpdatePassword> {}
const FirstAccessPassword: React.FC<FirstAccessPasswordProps> = (props) => {
  const {
    values: formValues,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
  } = props;

  const { green, black} = useColor();

  const {
    visible: showNewPassword,
    toggleVisibility: handleClickShowNewPassword,
  } = usePasswordVisibility();
  const {
    visible: showNewPasswordConfirmation,
    toggleVisibility: handleClickShowNewPasswordConfirmation,
  } = usePasswordVisibility();

  const onMouseDownPassword = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  }

  return (
    <Box>
      <Box sx={{ my: 1 }}>
        <Typography
          color="primary"
          sx={{
            fontSize: "3.5rem",
            fontWeight: "bold",
            fontFamily: "Poppins",
          }}
        >
          Primeiro Acesso
        </Typography>

        <Typography
          color="subTitles.main"
          sx={{
            fontWeight: 500,
            fontSize: "1.6rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Seu primeiro acesso? Crie uma nova senha para garantir a sua segurança
        </Typography>
      </Box>

      <Box sx={{ my: 4, mx: 4 }}>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <StyledTextField
              id="email"
              type="email"
              name={UpdatePasswordFormFields.email}
              value={formValues.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
              fullWidth
              sx={{ borderRadius: "1rem", }}
              labelprops={{
                label: "Email",
                hasTouched: Boolean(touched.email),
                hasErros: errors.email as string,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" sx={{ m: 0 }}>
                      {touched.email && !errors.email ? (
                        <Check sx={{ color: green, opacity: "1",}} />
                      ) : (
                        <Check sx={{ color: black, opacity: "0" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              type={showPass(showNewPassword)}
              name={UpdatePasswordFormFields.newPass}
              value={formValues.newPass}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              labelprops={{
                label: "Nova senha",
                hasTouched: touched.newPass,
                hasErros: errors.newPass,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={(e) => onMouseDownPassword(e)}
                      sx={{ m: 0 }}
                    >
                      {showNewPassword ? (
                        <NoVisibleIcon
                          sx={{ color: "black", opacity: "0.5" }}
                        />
                      ) : (
                        <VisibleIcon
                          sx={{ color: "primary.main", opacity: "0.5" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              type={showNewPasswordConfirmation ? "text" : "password"}
              name={UpdatePasswordFormFields.newPassConfirmation}
              value={formValues.newPassConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              labelprops={{
                label: "Confirme a nova senha",
                hasTouched: touched.newPassConfirmation,
                hasErros: errors.newPassConfirmation,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowNewPasswordConfirmation}
                      onMouseDown={(e) => onMouseDownPassword(e)}
                      sx={{ m: 0 }}
                    >
                      {showNewPasswordConfirmation ? (
                        <NoVisibleIcon
                          sx={{ color: "black", opacity: "0.5" }}
                        />
                      ) : (
                        <VisibleIcon
                          sx={{ color: "primary.main", opacity: "0.5" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordStrengthBar
              newPass={formValues.newPass}
              newPassConfirmation={formValues.newPassConfirmation}
            />
          </Grid>
        </Grid>
      </Box>

      <StyledButton
        variant="contained"
        color="primary"
        type="submit"
        loading={isSubmitting}
        disabled={!isValid}
        sx={{ px: 4, my: 2 }}
      >
        Trocar Senha
      </StyledButton>
    </Box>
  );
};

export default FirstAccessPassword;
