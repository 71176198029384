import{ useTheme } from "@mui/material";

export const useColor = () => {
  const theme = useTheme();

  const primary = theme.palette.primary.main;
  const subTitles = theme.palette.subTitles.main;
  const background = theme.palette.background.default;

  const green   = theme.palette.tagsAndStatus[0];
  const yellow  = theme.palette.tagsAndStatus[1];
  const red     = theme.palette.tagsAndStatus[2];
  const gray    = theme.palette.tagsAndStatus[6];
  const white   = theme.palette.tagsAndStatus[7];
  const black   = theme.palette.tagsAndStatus[8];


  return { primary, subTitles, background, green, yellow, red, gray, white, black };
}