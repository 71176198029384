import * as yup from "yup";

import { strongPasswordValidation } from "../../../utils/passwordValidation";

export const validationSchemaChangePassword = yup.object().shape({
  newPass: strongPasswordValidation,
  confirmPass: yup
    .string()
    .oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais.')
    .required("Este campo é obrigatório"),
});
